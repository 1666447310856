<template>
  <v-col v-if="matchResult">
    <v-alert v-if="matchResult.isNamesMatch"
             type="success"
             outlined
             class="mb-1"
             style="="
    >
      Matched
    </v-alert>
    <v-alert v-if="!matchResult.isNamesMatch"
             type="info"
             outlined
             class="mb-1"
    >
      Not matched
    </v-alert>
  </v-col>

  <v-col v-else-if="error">
    <RequestErrorMessage :error="error" />
  </v-col>

</template>

<script>
import RequestErrorMessage from '@/components/common/RequestErrorMessage';

export default {
  components: {
    RequestErrorMessage,
  },
  props: {
    matchResult: Object,
    error: Error,
  },
};
</script>
