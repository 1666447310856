<template>
  <v-container fluid>
    <v-row align="center" justify="center" style="height: 80vh">
      <NamesComparisonForm/>
    </v-row>
  </v-container>
</template>

<script>

import NamesComparisonForm from '@/components/names-compare/NamesComparisonForm';

export default {
  components: { NamesComparisonForm },
};
</script>

<style scoped>

</style>
