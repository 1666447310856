<template>
  <v-form @submit.prevent="compare" v-model="formValid">
    <v-container fluid class="elevation-3 pa-10">
      <v-row class="title pb-5">Compare one name to another</v-row>
      <v-row justify="center">
        <v-text-field label="Input first name"
                      v-model.trim="firstName"
                      required
                      clearable
                      full-width
                      :rules="rules"
                      @input="touched=true"
        />
        <v-icon large class="pa-5">swap_horizontal_circle</v-icon>
        <v-text-field label="Input second name"
                      v-model.trim="secondName"
                      required
                      clearable
                      full-width
                      :rules="rules"
                      @input="touched=true"
        />
      </v-row>
      <v-row class="pt-2" v-if="matchResult && matchResult.isNamesMatch && !touched">
        <v-text-field dense readonly label="Phonetic code" :value="matchResult.name1codes" />
        <v-text-field dense readonly label="Phonetic code" :value="matchResult.name2codes" class="pl-10 ml-10" />
      </v-row>

      <v-row v-if="matchResult && matchResult.isNamesMatch && !touched" class="pt-3">
        <v-col class="pl-0">
          <v-text-field label="Phonetic score" dense readonly :value="matchResult.phonetixScore" />
        </v-col>
        <v-col class="pr-0">
          <v-text-field label="Distance" dense readonly :value="Math.round(Math.max(matchResult.weightedAverageLong, matchResult.weightedAverageShort) * 100) / 100" />
        </v-col>
      </v-row>
      <v-row>
        <v-switch label="It's an individual"
                  v-model="isIndividual"
                  class="mt-0"
                  @input="touched=true"
        />
      </v-row>
      <v-row justify="start" align="center" style="height: 100px;">
        <v-col cols="4" class="pl-0">
          <v-btn x-large type="submit" color="primary" :disabled="!formValid">Compare</v-btn>
        </v-col>
        <v-col cols="8" v-if="!touched">
          <NamesComparisonAlerts :matchResult="matchResult" :error="error" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { required } from '@/utils/validations';
import NamesComparisonAlerts from './NamesComparisonAlerts';

export default {
  components: {
    NamesComparisonAlerts,
  },
  data() {
    return {
      touched: false,
      formValid: false,
      rules: [
        required,
      ],
      firstName: '',
      secondName: '',
      isIndividual: false,
      matchResult: null,
      error: null,
    };
  },
  methods: {
    async compare() {
      try {
        this.matchResult = null;
        this.error = null;
        const response = await this.$apiClient.compareNames(this.firstName, this.secondName, this.isIndividual);
        // console.log('DEBUG :', response.data.name1codes, response.data.name2codes);
        response.data.name1codes = response.data.name1codes.replaceAll(',', '-');
        response.data.name2codes = response.data.name2codes.replaceAll(',', '-');
        this.matchResult = response.data;
        this.touched = false;
      } catch (error) {
        this.error = error;
        this.touched = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
